import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchNeighborhoods(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/neighborhoods', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/pages/${id}`)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    addPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/pages', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPage(ctx, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/pages/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
